<template>
  <div id="contact-us">
    <loading :can-cancel="true"></loading>
    <div class="contact-us-container">
      <h5 class="title">{{ data ? data.title : "****" }}</h5>
      <div class="content">
        {{ data ? data.text : "****" }}
      </div>
      <img class="image" src="../assets/images/contant-us.svg" alt="" />
      <div class="map-container">
        <div class="map">
          <l-map :center="center">
            <l-tile-layer :url="url"></l-tile-layer>
          </l-map>
        </div>
        <div class="social-item">
          <img src="../assets/images/twitter-blue.svg" />
          <img src="../assets/images/instagram-blue.svg" />
          <img src="../assets/images/telegram-blue.svg" />
          <img src="../assets/images/linkdin-blue.svg" />
        </div>
      </div>
      <div class="address-container">
        <div class="item">
          <img src="../assets/images/location.svg" alt="" />
          <div class="text">
            {{ data ? data.address : "****" }}
          </div>
        </div>
        <div class="item">
          <img src="../assets/images/phone.svg" alt="" />
          <div class="text">{{ data ? data.phone : "****" }}</div>
        </div>
        <div class="item">
          <img src="../assets/images/email.svg" alt="" />
          <div class="text">{{ data ? data.email : "****" }}</div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "../components/Footer";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";

import { LMap, LTileLayer } from "vue2-leaflet";

export default {
  components: {
    Footer,
    Loading,
    LMap,
    LTileLayer
  },
  data() {
    return {
      data: {},
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 3,
      center: [35.716023, 51.406863],
      bounds: null
    };
  },
  mounted() {
    this.getContactUs();
  },
  methods: {
    async getContactUs() {
      let loader = this.$loading.show();
      try {
        let response = await axios("/contact-us");
        this.data = response.data;
        loader.hide();
      } catch (error) {
        console.log(error);
        loader.hide();
      }
      loader.hide();
    },
    zoomUpdated(zoom) {
      this.zoom = zoom;
    },
    centerUpdated(center) {
      this.center = center;
    },
    boundsUpdated(bounds) {
      this.bounds = bounds;
    }
  }
};
</script>
<style scoped>
#contact-us {
  min-width: 100%;
}
.contact-us-container {
  /* padding: 0 70px; */
  padding: 0 10px;
  margin-top: 32px;
}
.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 31px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #070d59;
  margin: 0 auto;
  font-family: "Vazir Medium FD";
}
.content {
  margin-top: 32px;
  font-size: 16px;
  line-height: 50px;
  width: 100%;
  color: #121212;
  text-align: center;
}
.image {
  display: none;
  position: absolute;
  top: 250px;
  left: 30px;
}
.map-container {
  display: flex;
  width: 100%;
  /* margin-top: 35px; */
  flex-direction: column;
}
.map {
  height: 260px;
  /*background-color: khaki;*/
  width: 100%;
  /*background: url("../assets/images/map.png");*/
  border-radius: 10px;
  box-shadow: 0px 0px 8px rgb(0 0 0 / 30%);
}
.social-item {
  display: flex;
  /* flex-direction: column;
  margin-right: 17px;
  justify-content: space-between;
  min-height: 260px;
  max-height: 260px; */

  flex-direction: row;
  margin: 0;
  justify-content: space-between;
  margin-top: 15px;
}
.address-container {
  margin-top: 15px;
}
.item {
  display: flex;
  margin-bottom: 21px;
}
.text {
  margin-right: 10px;
}

@media (min-width: 720px) {
  .content {
    text-align: start;
  }
  .map-container {
    flex-direction: row;
  }
  .social-item {
    flex-direction: column;
    margin-right: 10px;
  }
}
@media (min-width: 960px) {
  .contact-us-container {
    padding: 0 70px;
    min-height: 760px;
  }
  .map-container {
    width: 60%;
    margin-top: 23px;
  }
  .image {
    display: block;
    position: absolute;
    width: 35%;
  }
}
@media (min-width: 1140px) {
  .contact-us-container {
    padding: 0 70px;
    min-height: 100vh;
  }
}
</style>
